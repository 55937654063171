import type { CoingeckoCoinsInfo } from "api/types/coinInfo";

export const coingeckoCoinsList: CoingeckoCoinsInfo[] = [
  { id: "bitcoin", symbol: "btc", name: "Bitcoin" },
  { id: "tether-gold", symbol: "xaut", name: "Tether Gold" },
  { id: "dogs-2", symbol: "dogs", name: "Dogs" },
  { id: "hamster-kombat", symbol: "hmstr", name: "Hamster Kombat" },
  { id: "notcoin", symbol: "not", name: "Notcoin" },
  { id: "tether", symbol: "usdt", name: "Tether" },
  { id: "floki", symbol: "floki", name: "FLOKI" },
  { id: "the-open-network", symbol: "ton", name: "Toncoin" },
  { id: "official-trump", symbol: "trump", name: "Official Trump" },
  { id: "usd-coin", symbol: "usdc", name: "USDC" },
  { id: "algorand", symbol: "algo", name: "Algorand" },
  { id: "binancecoin", symbol: "bnb", name: "BNB" },
  { id: "the-graph", symbol: "grt", name: "The Graph" },
  { id: "stobox-token", symbol: "stbu", name: "Stobox" },
  { id: "cardano", symbol: "ada", name: "Cardano" },
  { id: "cosmos", symbol: "atom", name: "Cosmos Hub" },
  { id: "ethereum", symbol: "eth", name: "Ethereum" },
  { id: "matic-network", symbol: "matic", name: "Polygon" },
  { id: "uniswap", symbol: "uni", name: "Uniswap" },
  { id: "tron", symbol: "trx", name: "TRON" },
  { id: "polkadot", symbol: "dot", name: "Polkadot" },
  { id: "chainlink", symbol: "link", name: "Chainlink" },
  { id: "dai", symbol: "dai", name: "Dai" },
  { id: "solana", symbol: "sol", name: "Solana" },
];
