import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/Inputs";
import { CoinCell } from "components/Table";
import { CloseModalButton } from "components/Buttons";
import BasicModal from "./BasicModal";
// import { bankVariant } from "./DepositModal";
// import { sepaVariant } from "./WithdrawModal";
import type { CurrencyInterface } from "helpers/types";

import {
  TopSemiContent,
  ModalHeading,
  CurrencyOptions,
  CurrencyOption,
  CurrencyLine,
  BeingSelected,
  SemiContent,
} from "./styled";

import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";

export interface SelectPaymentProps {
  options: CurrencyInterface[] | null;
  selected?: CurrencyInterface;
  isOpen: boolean;
  // isDeposit?: boolean;
  // isWithdrawal?: boolean;
  onClose: () => void;
  setValue: (val: CurrencyInterface) => void;
}

const PaymentSelect: FC<SelectPaymentProps> = ({
  isOpen,
  onClose,
  options = null,
  selected,
  setValue,
  // isDeposit,
  // isWithdrawal,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");

  const onSelect = (option: CurrencyInterface) => {
    setValue(option);
    onClose();
  };

  const searchedOptions = useMemo(
    () =>
      options?.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      ),
    [search, options]
  );

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={() => setSearch("")}
      onClose={onClose}
      isInsideModal
    >
      <SemiContent>
        <CloseModalButton onClose={onClose} label={t("CLOSE_SELECT")} />
        <TopSemiContent>
          <ModalHeading>{t("SELECT_ASSET")}</ModalHeading>
          <TextInput
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
            leftIcon={<SearchIcon />}
            placeholder={t("SEARCH")}
          />
        </TopSemiContent>
        <CurrencyOptions>
          {searchedOptions?.map((payment) => (
            <CurrencyOption
              key={payment.name}
              onClick={() => onSelect(payment)}
              $isSelected={payment.name === selected?.name}
            >
              <CurrencyLine>
                <CoinCell text={payment.name} coin={payment.name} />
              </CurrencyLine>
              <BeingSelected>
                {payment.name === selected?.name && <CheckIcon />}
              </BeingSelected>
            </CurrencyOption>
          ))}
        </CurrencyOptions>
      </SemiContent>
    </BasicModal>
  );
};

export default PaymentSelect;
