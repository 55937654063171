import axios from "axios";
import type {
  ByBitGetKlineReq,
  ByBitGetTickerReq,
  ByBitGetOrderbookReq,
  ByBitGetInstrumentsListReq,
  ByBitGetRecentTradeReq,
  ByBitGetKlineRes,
  ByBitGetOrderbookRes,
  ByBitGetInstrumentsListRes,
  ByBitGetRecentTradeRes,
  ByBitGetAccountRes,
  ByBitGetOrdersListReq,
  ByBitGetOrdersListRes,
  ByBitGetGetBalancesRes,
  ByBitGetDepositRecordsReq,
  ByBitGetDepositRecordsRes,
  ByBitGetDepositWithdrawAssetsRes,
  ByBitGetDepositAddressReq,
  ByBitGetWithdrawRecordsReq,
  ByBitGetWithdrawRecordsRes,
  ByBitWithdrawReq,
  ByBitCreateOrderReq,
  ByBitCancelOrderReq,
  ByBitCancelOrderRes,
  ByBitGetFeeRatesRes,
} from "./types/bybit";
import { ByBitDepositAddress, ByBitGetTickerData } from "helpers/types";

export const getByBitKline = async (reqData: ByBitGetKlineReq) =>
  axios.get<ByBitGetKlineRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}market/kline`,
    {
      params: {
        ...reqData,
      },
    }
  );

export const getByBitTicker = async (reqData: ByBitGetTickerReq) =>
  axios.get<ByBitGetTickerData>(
    `${process.env.REACT_APP_BYBIT_API_URL}market/ticker`,
    {
      params: {
        ...reqData,
      },
    }
  );

export const getByBitOrderbook = async (reqData: ByBitGetOrderbookReq) =>
  axios.get<ByBitGetOrderbookRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}market/orderbook`,
    {
      params: {
        ...reqData,
      },
    }
  );

export const getByBitInstrumentsList = async (
  reqData: ByBitGetInstrumentsListReq
) =>
  axios.get<ByBitGetInstrumentsListRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}market/instruments/list`,
    {
      params: {
        ...reqData,
      },
    }
  );

export const getByBitRecentTrade = async (reqData: ByBitGetRecentTradeReq) =>
  axios.get<ByBitGetRecentTradeRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}market/recent-trade`,
    {
      params: {
        ...reqData,
      },
    }
  );

export const getByBitAccount = async () =>
  axios.get<ByBitGetAccountRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}account`
  );

export const getByBitFees = async () =>
  axios.get<ByBitGetFeeRatesRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}account/fee-rate`,
    {
      params: {
        category: "spot",
      },
    }
  );

export const getByBitOrdersList = async (reqData: ByBitGetOrdersListReq) =>
  axios.get<ByBitGetOrdersListRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}order/realtime/list`,
    {
      params: {
        ...reqData,
      },
    }
  );

export const getByBitUserBalances = async () =>
  axios.get<ByBitGetGetBalancesRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}asset/balance/list`,
    {}
  );

export const getByBitDepositRecordsList = async (
  reqData: ByBitGetDepositRecordsReq
) =>
  axios.get<ByBitGetDepositRecordsRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}deposit/record/list`,
    {
      params: {
        ...reqData,
      },
    }
  );

export const getByBitDepositAssetsList = async () =>
  axios.get<ByBitGetDepositWithdrawAssetsRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}deposit/asset/list`
  );

export const getByBitDepositAddress = async (
  reqData: ByBitGetDepositAddressReq
) =>
  axios.get<ByBitDepositAddress>(
    `${process.env.REACT_APP_BYBIT_API_URL}deposit/address`,
    {
      params: {
        ...reqData,
      },
    }
  );

export const getByBitWithdrawRecordsList = async (
  reqData: ByBitGetWithdrawRecordsReq
) =>
  axios.get<ByBitGetWithdrawRecordsRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}withdraw/record/list`,
    {
      params: {
        ...reqData,
      },
    }
  );

export const getByBitWithdrawAssetsList = async () =>
  axios.get<ByBitGetDepositWithdrawAssetsRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}withdraw/asset/list`
  );

export const byBitWithdraw = async (data: ByBitWithdrawReq) =>
  axios.post(`${process.env.REACT_APP_BYBIT_API_URL}withdraw`, data);

export const createOrder = async (data: ByBitCreateOrderReq) =>
  axios.post(`${process.env.REACT_APP_BYBIT_API_URL}order`, data);

export const cancelOrder = async (data: ByBitCancelOrderReq) =>
  axios.post<ByBitCancelOrderRes>(
    `${process.env.REACT_APP_BYBIT_API_URL}order/cancel`,
    data
  );
