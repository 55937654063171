import { FC, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "helpers/consts";
import { AppText } from "components";
import {
  ModalErrorContainer,
  ModalErrorStatus,
  // InputContainer,
} from "../styled";

interface ModalErrorProps {
  text: string;
  style?: CSSProperties;
  darkTheme?: boolean;
}

const ModalError: FC<ModalErrorProps> = ({ text, style, darkTheme }) => {
  const { t } = useTranslation();
  return (
    <ModalErrorContainer style={style} $darkTheme={darkTheme}>
      <ModalErrorStatus $darkTheme={darkTheme}>
        <AppText fontSize={12} color={colors.error_700}>
          {t("WARNING")}
        </AppText>
      </ModalErrorStatus>
      <AppText fontSize={12} color={colors.error_700}>
        {text}
      </AppText>
    </ModalErrorContainer>
  );
};

export default ModalError;
