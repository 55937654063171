import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import { Accordion } from "components";
import {
  toLocaleStringWithCurrency,
  normolizeCurrenciesDecimals,
} from "helpers/funcs";
import { InfoRows } from "../styled";
import type {
  AvailableTradeOptions,
  MarketsInterface,
  CurrencyInterface,
  TradeOperationType,
} from "helpers/types";

interface TradeInfoRowsProps {
  leftCurrencyValue: string;
  rightCurrencyValue: string;
  leftCurrency: AvailableTradeOptions | null;
  rightCurrency: AvailableTradeOptions | null;
  currentFee: number;
  cost: number;
  currentExchangeMarket: MarketsInterface | null;
  currencies: CurrencyInterface[] | null;
  marketType: TradeOperationType;
}

const TradeInfoRows: FC<TradeInfoRowsProps> = ({
  leftCurrencyValue,
  rightCurrencyValue,
  leftCurrency,
  rightCurrency,
  currentFee,
  cost,
  currentExchangeMarket,
  currencies,
  marketType,
}) => {
  const { t } = useTranslation();

  return (
    <Accordion
      label={t("YOU_RECEIVE")}
      subLabel={`${marketType !== "limit" ? "≈ " : ""}${
        rightCurrency
          ? rightCurrencyValue
            ? toLocaleStringWithCurrency(
                Number(rightCurrencyValue),
                rightCurrency,
                currencies
              )
            : `0 ${rightCurrency}`
          : 0
      }`}
    >
      <InfoRows>
        {currentExchangeMarket && (
          <ModalRow
            keyText={t(marketType === "limit" ? "LIMIT_ORDER_PRICE" : "RATE")}
            value={`${marketType !== "limit" ? "≈ " : ""}${
              currentExchangeMarket
                ? toLocaleStringWithCurrency(
                    cost,
                    currentExchangeMarket.rightCurrencyName,
                    currencies
                  )
                : toLocaleStringWithCurrency(cost, "USD")
            }`}
            isBold
          />
        )}
        <ModalRow
          keyText={t("YOU_SPEND")}
          value={
            leftCurrency
              ? leftCurrencyValue
                ? `${toLocaleStringWithCurrency(
                    leftCurrencyValue,
                    leftCurrency,
                    currencies
                  )}`
                : `0 ${leftCurrency}`
              : 0
          }
        />

        <ModalRow
          keyText={t("FEES")}
          value={`${normolizeCurrenciesDecimals(
            currentFee,
            leftCurrency === "STBU" || leftCurrency === "GXAG"
              ? rightCurrency === "USD" || rightCurrency === "EUR"
                ? "USDT"
                : rightCurrency || "USD"
              : leftCurrency === "USD" || leftCurrency === "EUR"
              ? "USDT"
              : leftCurrency || "USD",
            currencies,
            "forView"
          )} ${
            leftCurrency === "STBU" || leftCurrency === "GXAG"
              ? rightCurrency || "USD"
              : leftCurrency || "USD"
          }`}
        />
      </InfoRows>
    </Accordion>
  );
};

export default TradeInfoRows;
