import { FC, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  CHART_TYPE,
  CHART_PERIODS,
  currencyIcons,
  //   coinsSelectNames,
} from "helpers/consts";
import { AppText, Chip, Heading } from "components";
import {
  toLocaleStringWithCurrency,
  toLocaleStringWithCurrencyNoDecimals,
} from "helpers/funcs";
import { getCoinOHLCByFiat } from "api/coinInfo";
import {
  MainContainer,
  TopContent,
  HeadingContent,
  LineContent,
  ChartContent,
} from "./styled";
import Chart, { OHLCInterface } from "./Chart";
import { CurrenciesStore, GlobalStore } from "stores";
import { coingeckoCoinsList } from "./coingeckoCoinsList";
import type {
  AvailableTradeOptions,
  GetCoinOHLCInterface,
  MarketsInterface,
} from "helpers/types";

interface TradesChartInterface {
  market?: MarketsInterface | null;
  noMarket?: boolean;
  fiatMarket?: {
    [k: string]: number;
  } | null;
  coinCurrency?: AvailableTradeOptions | null;
  fiatCurrency?: AvailableTradeOptions | null;
}
const TradesChart: FC<TradesChartInterface> = ({
  market,
  fiatMarket,
  coinCurrency,
  fiatCurrency,
  noMarket,
}) => {
  const { t } = useTranslation();
  const { prices } = CurrenciesStore;
  const [OHLC, setOHLC] = useState<OHLCInterface[]>([]);
  const [shouldChartRender, setShouldChartRender] = useState<boolean>(true);
  const [OHLCPeriod] = useState(CHART_PERIODS[0]);
  //   const [rightSelectOptions] = useState([]);

  const getCoinOHLC = ({ fiatId, coinId, days }: GetCoinOHLCInterface) => {
    getCoinOHLCByFiat({
      fiatId,
      coinId,
      days,
    })
      .then(({ data }) => {
        const newOHLC = data.map(
          ([time, open, high, low, close]): OHLCInterface => ({
            // @ts-ignore
            time: time / 1000,
            open,
            high,
            low,
            close,
          })
        );
        setOHLC(newOHLC);
      })
      .catch((err) => {
        setShouldChartRender(false);
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      });
  };

  useEffect(() => {
    setShouldChartRender(true);

    const getChartInfo = async () => {
      let defaultSelectedCoin;

      if (market) {
        defaultSelectedCoin = coingeckoCoinsList.find(
          ({ symbol }) => symbol === market?.leftCurrencyName?.toLowerCase()
        );
      }

      if (coinCurrency) {
        defaultSelectedCoin = coingeckoCoinsList.find(
          ({ symbol }) => symbol === coinCurrency.toLowerCase()
        );
      }

      if (!defaultSelectedCoin) {
        setShouldChartRender(false);
        return;
      }

      getCoinOHLC({
        fiatId: fiatCurrency ? fiatCurrency.toUpperCase() : "USD",
        coinId: defaultSelectedCoin.id,
        days: OHLCPeriod.value,
      });
    };
    getChartInfo();
  }, [market, OHLCPeriod.value, coinCurrency, fiatCurrency]);

  const priceDiff = useMemo<number>(() => {
    if (!noMarket && prices?.[`${market?.leftCurrencyName}/USD`]?.close) {
      return Number(
        Number(
          ((Number(prices[`${market?.leftCurrencyName}/USD`].close) -
            Number(prices[`${market?.leftCurrencyName}/USD`].open)) /
            Number(prices[`${market?.leftCurrencyName}/USD`].open)) *
            100
        ).toFixed(2)
      );
    }
    if (
      noMarket &&
      prices?.[`${coinCurrency?.toUpperCase()}/${fiatCurrency?.toUpperCase()}`]
        ?.close
    ) {
      return Number(
        Number(
          ((Number(
            prices[
              `${coinCurrency?.toUpperCase()}/${fiatCurrency?.toUpperCase()}`
            ].close
          ) -
            Number(
              prices[
                `${coinCurrency?.toUpperCase()}/${fiatCurrency?.toUpperCase()}`
              ].open
            )) /
            Number(
              prices[
                `${coinCurrency?.toUpperCase()}/${fiatCurrency?.toUpperCase()}`
              ].open
            )) *
            100
        ).toFixed(2)
      );
    }
    return 0;
  }, [prices, market?.leftCurrencyName, fiatCurrency, coinCurrency, noMarket]);

  return (
    <MainContainer>
      <TopContent>
        <HeadingContent>
          <Heading>
            {market?.leftCurrencyName || coinCurrency?.toUpperCase()}{" "}
            {t("PRICE")}
          </Heading>
          {(prices?.[`${market?.leftCurrencyName}/USD`]?.close ||
            (fiatMarket && fiatCurrency)) && (
            <LineContent>
              <AppText fontSize={30} lineHeight={38} fontWeight={600}>
                {Number(prices?.[`${market?.leftCurrencyName}/USD`]?.close)
                  ? toLocaleStringWithCurrency(
                      Number(
                        prices?.[`${market?.leftCurrencyName}/USD`]?.close
                      ),
                      "USD"
                    )
                  : fiatCurrency
                  ? toLocaleStringWithCurrencyNoDecimals(
                      Number(fiatMarket?.[fiatCurrency.toLowerCase()]),
                      fiatCurrency
                    )
                  : "-"}
              </AppText>
              {priceDiff !== 0 && (
                <Chip isSuccess={priceDiff >= 0} isLarge withBorder withArrow>
                  {isFinite(priceDiff) ? priceDiff : 0}%
                </Chip>
              )}
            </LineContent>
          )}
        </HeadingContent>
        {market?.leftCurrencyName
          ? currencyIcons[market?.leftCurrencyName]
          : coinCurrency
          ? currencyIcons[coinCurrency?.toUpperCase()]
          : null}
      </TopContent>
      {!noMarket && shouldChartRender && (
        <ChartContent>
          <Chart OHLC={OHLC} chartType={CHART_TYPE.LINE} />
        </ChartContent>
      )}
    </MainContainer>
  );
};

export default observer(TradesChart);
