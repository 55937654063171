import {
  FC,
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
  useCallback,
  FormEvent,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import { WalletStore, CurrenciesStore, UserStore, GlobalStore } from "stores";
import {
  ModalButton,
  CloseModalButton,
  CurrencyButton,
  MainButton,
} from "components/Buttons";
import { TwoFaModal, ModalWarningInfo, ModalError } from "components/Modals";
import { SelectItemProps } from "components/Inputs/types";
import {
  CurrencyInput,
  RangeInput,
  Select,
  StyledTextInput,
  TextInput,
} from "components/Inputs";
import countries from "helpers/countries";
import WithdrawInfoRows from "./WithdrawInfoRows";
import { AppText, Checkbox, Warning, VerificationWarning } from "components";
import PaymentSelect from "../PaymentSelect";
import {
  normolizeCurrenciesDecimals,
  toLocaleStringWithCurrency,
} from "helpers/funcs";
import { add2FAVerification } from "api/user";
import {
  ModalContent,
  ModalHeading,
  InnerContentForm,
  MainContainer,
  TransactionGroup,
  InfoLine,
  SemiContainer,
  InputContainer,
} from "../styled";
// import { bankWithdraw, transferName } from "./index";
import {
  exceedAmount,
  lowerThanMinAmount,
  higherThanMaxAmount,
} from "helpers/errorMessages";
import type { CurrencyInterface, AvailableTradeOptions } from "helpers/types";

import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock-unlocked.svg";

interface MainWithdrawStepProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setSkipConfirm: Dispatch<SetStateAction<boolean>>;
  skipConfirm: boolean;
  onClose: () => void;
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  setCurrency: Dispatch<SetStateAction<AvailableTradeOptions | null>>;
  setCurrencyValue: Dispatch<SetStateAction<string>>;
  paymentType?: CurrencyInterface;
  setPaymentType: (val: CurrencyInterface | undefined) => void;
  fees: number;
  fixedFees: number;
  percentFees: number;
  currencies: CurrencyInterface[] | null;
  networkOptions?: Array<SelectItemProps>;
  chosenNetwork: string;
  setChosenNetwork: Dispatch<SetStateAction<string>>;
  withdrawAddress: string;
  setWithdrawAddress: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  twoFACode: string;
  setTwoFACode: Dispatch<SetStateAction<string>>;
  withdrawMemo: string;
  setWithdrawMemo: Dispatch<SetStateAction<string>>;
  hasMemo: boolean;
  isLoading: boolean;
  values: { [field: string]: any };
  errors: { [field: string]: string };
  handleChange: (e: any) => void;
  resetForm: () => void;
  receiverEmail: string;
  setReceiverEmail: Dispatch<SetStateAction<string>>;
  transferNote: string;
  setTransferNote: Dispatch<SetStateAction<string>>;
}

const MainWithdrawStep: FC<MainWithdrawStepProps> = ({
  onSubmit,
  onClose,
  currencyValue,
  currency,
  setCurrencyValue,
  paymentType,
  setPaymentType,
  skipConfirm,
  setSkipConfirm,
  fees,
  fixedFees,
  percentFees,
  networkOptions,
  chosenNetwork,
  setChosenNetwork,
  setCurrency,
  withdrawAddress,
  setWithdrawAddress,
  setIsLoading,
  twoFACode,
  setTwoFACode,
  withdrawMemo,
  setWithdrawMemo,
  hasMemo,
  isLoading,
  values,
  errors,
  handleChange,
  resetForm,
  receiverEmail,
  setReceiverEmail,
  transferNote,
  setTransferNote,
}) => {
  const { t } = useTranslation();
  const { walletsList } = WalletStore;
  const { currencies } = CurrenciesStore;
  const {
    user: { is2FAEnabled, level },
  } = UserStore;
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isWarnConfirmed, setIsWarnConfirmed] = useState<boolean>(false);
  const [twoFaUrl, setTwoFaUrl] = useState<string>("");
  const [isTwoFaOpen, setIsTwoFaOpen] = useState<boolean>(false);

  const closeSelect = () => setIsSelectOpen(false);
  const openSelect = () => setIsSelectOpen(true);

  const currenciesOptions = useMemo<CurrencyInterface[] | null>(() => {
    if (currencies) {
      return currencies.reduce(
        (acc: CurrencyInterface[], el: CurrencyInterface) => {
          if (
            el.withdrawEnabled &&
            walletsList?.find(({ currencyName }) => currencyName === el.name) &&
            !acc.find(({ name }) => name === el.name)
          ) {
            acc.push(el);
          }
          return acc;
        },
        []
      );
    }
    return null;
  }, [currencies, walletsList]);

  const currencyBalance = useMemo<number>(() => {
    const leftBalance = walletsList?.find(
      ({ currencyName }) => currency === currencyName
    )?.balance;
    return !currency || !leftBalance
      ? 0
      : Number(normolizeCurrenciesDecimals(leftBalance, currency, currencies));
  }, [walletsList, currency, currencies]);

  const fullCurrencyBalance = useMemo<number>(() => {
    const leftBalance = walletsList?.find(
      ({ currencyName }) => currency === currencyName
    )?.balance;

    return !currency || !leftBalance ? 0 : Number(leftBalance);
  }, [walletsList, currency]);

  const minWithdraw = useMemo<number>(
    () =>
      ((networkOptions && networkOptions.length > 1 && chosenNetwork) ||
        (networkOptions && networkOptions.length === 1)) &&
      paymentType?.minWithdraw
        ? Number(paymentType.minWithdraw)
        : 0,
    [paymentType, networkOptions, chosenNetwork]
  );

  const maxWithdraw = useMemo<number>(
    () =>
      ((networkOptions && networkOptions.length > 1 && chosenNetwork) ||
        (networkOptions && networkOptions.length === 1)) &&
      paymentType?.maxWithdraw
        ? Number(paymentType.maxWithdraw)
        : 0,
    [paymentType, networkOptions, chosenNetwork]
  );

  const hasError = useMemo<string | null>(() => {
    if (!currencyValue) {
      return null;
    }
    if (
      Number(currencyValue) > 0 &&
      (fees ? Number(currencyValue) + fees : Number(currencyValue)) >
        fullCurrencyBalance
    ) {
      return t(exceedAmount);
    }
    if (
      // chosenNetwork !== transferName &&
      minWithdraw > 0 &&
      currencyValue &&
      Number(currencyValue) < minWithdraw
    ) {
      return `${t(lowerThanMinAmount)} (${minWithdraw} ${currency})`;
    }

    if (
      // chosenNetwork !== transferName &&
      maxWithdraw > 0 &&
      currencyValue &&
      Number(currencyValue) > maxWithdraw
    ) {
      return `${t(higherThanMaxAmount)} (${maxWithdraw} ${currency})`;
    }
    return null;
  }, [
    // chosenNetwork,
    currency,
    t,
    maxWithdraw,
    minWithdraw,
    currencyValue,
    fees,
    fullCurrencyBalance,
  ]);

  const onPaymentChange = (option: CurrencyInterface) => {
    if (option.name === currency) {
      return;
    }
    setPaymentType(option);
    setCurrency(option.name);
    setCurrencyValue("");
    setWithdrawAddress("");
    setWithdrawMemo("");
    resetForm();
    if (currencies) {
      const network = currencies.filter((el) => el.name === option.name);
      setChosenNetwork(
        network && network.length === 1 ? network[0].networkCode || "" : ""
      );
      return;
    }

    setChosenNetwork("");
  };

  const countriesOptions = useMemo<Array<SelectItemProps>>(() => {
    return countries.map(({ name }) => ({
      value: name,
      label: name,
    }));
  }, []);

  const onSelectNetwork = useCallback(
    (networkValue: string) => {
      if (
        currencies &&
        paymentType &&
        networkValue !== paymentType?.networkCode
        // networkValue !== transferName &&
        // networkValue !== bankWithdraw
      ) {
        setPaymentType(
          currencies.find(
            ({ networkCode, name }) =>
              paymentType.name === name && networkCode === networkValue
          )
        );
      }
      setWithdrawAddress("");
      setWithdrawMemo("");
      setChosenNetwork(networkValue);
    },
    [
      currencies,
      paymentType,
      setChosenNetwork,
      setPaymentType,
      setWithdrawAddress,
      setWithdrawMemo,
    ]
  );

  const change2Fa = useCallback(() => {
    if (is2FAEnabled) {
      setIsTwoFaOpen(true);
      return;
    }
    setIsLoading(true);
    add2FAVerification()
      .then(({ data }) => {
        setTwoFaUrl(data.url);
        setIsTwoFaOpen(true);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  }, [is2FAEnabled, setIsLoading]);

  console.log(
    Number(currencyValue) <= 0 ||
      !!hasError ||
      !isWarnConfirmed ||
      // !chosenNetwork ||
      // !is2FAEnabled ||
      isLoading ||
      // chosenNetwork !== bankWithdraw &&
      // chosenNetwork !== transferName &&
      // withdrawAddress.length < 4
      (currency !== "EUR" && withdrawAddress.length < 4) ||
      (currency === "EUR" &&
        // chosenNetwork === bankWithdraw &&
        !values.beneficiaryName) ||
      (currency === "EUR" &&
        // chosenNetwork === bankWithdraw &&
        Object.keys(errors).length !== 0) ||
      // chosenNetwork !== transferName &&
      (hasMemo && withdrawMemo.length < 4) ||
      // (chosenNetwork === transferName && !receiverEmail) ||
      // (chosenNetwork === transferName && !transferNote) ||
      (skipConfirm && !twoFACode)
  );

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <CloseModalButton onClose={onClose} />
        <ModalHeading>{t("WITHDRAW")}</ModalHeading>
        <InnerContentForm onSubmit={(e) => onSubmit(e)}>
          <MainContainer>
            <TransactionGroup>
              <AppText color={colors.gray_500}>{t("CHOOSE_METHOD")}</AppText>
              <CurrencyButton
                value={paymentType?.name}
                icon={paymentType?.name}
                onClick={openSelect}
              />
            </TransactionGroup>

            {networkOptions && (
              <TransactionGroup>
                <Select
                  variants={networkOptions}
                  value={chosenNetwork}
                  setValue={onSelectNetwork}
                  label={t("SELECT_NETWORK")}
                />
              </TransactionGroup>
            )}
          </MainContainer>

          {level === "BASIC" || level === "JUNIOR" ? (
            <VerificationWarning text={t("WITHDRAW_VERIFY_REQUIRED")} />
          ) : (
            <>
              {paymentType?.name === "EUR" && level !== "SENIOR" ? (
                <VerificationWarning
                  text={`${paymentType.name} ${t(
                    "WITHDRAW_FIAT_VERIFY_REQUIRED"
                  )}`}
                />
              ) : (
                <>
                  <>
                    {chosenNetwork &&
                      // chosenNetwork !== bankWithdraw &&
                      // chosenNetwork !== transferName &&
                      paymentType?.networkName && (
                        <ModalWarningInfo
                          text={`${t("WITHDRAW_WARN_1")} ${
                            paymentType?.name
                          } ${t("TO")} ${paymentType?.networkName} ${t(
                            "WITHDRAW_WARN_2"
                          )}`}
                          subText={t("WITHDRAW_WARN_3")}
                        />
                      )}

                    <MainContainer $customMargin={12}>
                      {paymentType?.name !== "EUR" && (
                        // chosenNetwork !== bankWithdraw &&
                        // chosenNetwork !== transferName &&
                        <StyledTextInput
                          value={withdrawAddress}
                          setValue={(value) => setWithdrawAddress(value)}
                          leftAdornment={<AppText>Address</AppText>}
                        />
                      )}

                      {/* {chosenNetwork === transferName && (
                        <>
                          <InputContainer>
                            <AppText>{t("RECIPIENTS_EMAIL")}*</AppText>
                            <TextInput
                              name="receiverEmail"
                              placeholder=""
                              value={receiverEmail}
                              onChange={({ target: { value } }) =>
                                setReceiverEmail(value)
                              }
                            />
                          </InputContainer>

                          <InputContainer>
                            <AppText>{t("TRANSFER_NOTE")}*</AppText>
                            <TextInput
                              name="transferNote"
                              placeholder=""
                              multiline
                              value={transferNote}
                              onChange={({ target: { value } }) =>
                                setTransferNote(value)
                              }
                              inputProps={{ maxLength: 500 }}
                            />
                          </InputContainer>
                        </>
                      )} */}

                      {
                        // chosenNetwork === bankWithdraw &&
                        currency === "EUR" && (
                          <>
                            <InputContainer>
                              <AppText>Name*</AppText>
                              <TextInput
                                name="beneficiaryName"
                                placeholder={t("ENTER_NAME")}
                                value={values.beneficiaryName}
                                onChange={handleChange}
                                error={
                                  !!values.beneficiaryName &&
                                  Boolean(errors.beneficiaryName)
                                }
                                helperText={
                                  values.beneficiaryName &&
                                  t(errors.beneficiaryName || "")
                                }
                              />
                            </InputContainer>

                            <InputContainer>
                              <AppText>{t("COUNTRY")}*</AppText>
                              <Select
                                name="beneficiaryCountryCode"
                                placeholder={t("CHOOSE_COUNTRY")}
                                variants={countriesOptions}
                                value={values.beneficiaryCountryCode}
                                onChange={handleChange}
                                error={
                                  !!values.beneficiaryCountryCode &&
                                  Boolean(errors.beneficiaryCountryCode)
                                }
                                helperText={
                                  values.beneficiaryCountryCode &&
                                  t(errors.beneficiaryCountryCode || "")
                                }
                              />
                            </InputContainer>
                            <InputContainer>
                              <AppText>{t("CITY")}*</AppText>
                              <TextInput
                                name="beneficiaryCity"
                                placeholder={t("ENTER_CITY")}
                                value={values.beneficiaryCity}
                                onChange={handleChange}
                                error={
                                  !!values.beneficiaryCity &&
                                  Boolean(errors.beneficiaryCity)
                                }
                                helperText={
                                  values.beneficiaryCity &&
                                  t(errors.beneficiaryCity || "")
                                }
                              />
                            </InputContainer>
                            <InputContainer>
                              <AppText>Address*</AppText>
                              <TextInput
                                name="beneficiaryAddress"
                                placeholder={t("ENTER_ADDRESS")}
                                value={values.beneficiaryAddress}
                                onChange={handleChange}
                                error={
                                  !!values.beneficiaryAddress &&
                                  Boolean(errors.beneficiaryAddress)
                                }
                                helperText={
                                  values.beneficiaryAddress &&
                                  t(errors.beneficiaryAddress || "")
                                }
                              />
                            </InputContainer>
                            <InputContainer>
                              <AppText>{t("POST_CODE")}*</AppText>
                              <TextInput
                                name="beneficiaryPostCode"
                                placeholder={t("ENTER_POST_CODE")}
                                value={values.beneficiaryPostCode}
                                onChange={handleChange}
                                error={
                                  !!values.beneficiaryPostCode &&
                                  Boolean(errors.beneficiaryPostCode)
                                }
                                helperText={
                                  values.beneficiaryPostCode &&
                                  t(errors.beneficiaryPostCode || "")
                                }
                              />
                            </InputContainer>

                            <InputContainer>
                              <AppText>IBAN*</AppText>
                              <TextInput
                                name="beneficiaryIban"
                                placeholder={`${t("ENTER")} IBAN`}
                                value={values.beneficiaryIban}
                                onChange={handleChange}
                                error={
                                  !!values.beneficiaryIban &&
                                  Boolean(errors.beneficiaryIban)
                                }
                                helperText={
                                  values.beneficiaryIban &&
                                  t(errors.beneficiaryIban || "")
                                }
                              />
                            </InputContainer>

                            <InputContainer>
                              <AppText>Payment details*</AppText>
                              <TextInput
                                name="paymentReference"
                                placeholder={t("ENTER_DETAILS")}
                                value={values.paymentReference}
                                onChange={handleChange}
                                error={
                                  !!values.paymentReference &&
                                  Boolean(errors.paymentReference)
                                }
                                helperText={
                                  values.paymentReference &&
                                  t(errors.paymentReference || "")
                                }
                              />
                            </InputContainer>
                          </>
                        )
                      }

                      {
                        // chosenNetwork !== transferName &&
                        hasMemo && (
                          <StyledTextInput
                            value={withdrawMemo}
                            setValue={(value) => setWithdrawMemo(value)}
                            leftAdornment={<AppText>Memo</AppText>}
                          />
                        )
                      }

                      <TransactionGroup>
                        {
                          // chosenNetwork !== transferName &&
                          (minWithdraw !== 0 || maxWithdraw !== 0) && (
                            <InfoLine>
                              {minWithdraw !== 0 && (
                                <AppText color={colors.gray_900} fontSize={12}>
                                  Min {minWithdraw} {currency}{" "}
                                  {minWithdraw !== 0 && maxWithdraw !== 0
                                    ? " / "
                                    : ""}
                                </AppText>
                              )}

                              {maxWithdraw !== 0 && (
                                <AppText color={colors.gray_900} fontSize={12}>
                                  Max {maxWithdraw} {currency}
                                </AppText>
                              )}
                            </InfoLine>
                          )
                        }

                        <CurrencyInput
                          value={currencyValue}
                          setValue={(value) => setCurrencyValue(value)}
                          currency={currency}
                          currencies={currencies}
                          onClick={openSelect}
                          placeholder={
                            // chosenNetwork !== transferName &&
                            minWithdraw !== 0 ? `min ${minWithdraw}` : "0"
                          }
                          noSelect
                        />

                        <InfoLine>
                          <WalletIcon />
                          <AppText
                            color={colors.gray_600}
                            fontWeight={400}
                            fontSize={12}
                          >
                            {t("BALANCE")} {currencyBalance} {paymentType?.name}
                          </AppText>
                        </InfoLine>
                      </TransactionGroup>

                      <RangeInput
                        value={currencyValue}
                        setValue={setCurrencyValue}
                        balance={currencyBalance}
                        mainCurrency={currency}
                        currencies={currencies}
                        fees={fees}
                      />
                    </MainContainer>
                  </>

                  <SemiContainer>
                    <WithdrawInfoRows
                      currencyValue={currencyValue}
                      currency={currency}
                      fees={fees}
                      fixedFees={fixedFees}
                      percentFees={percentFees}
                      chosenNetwork={chosenNetwork}
                    />

                    <Checkbox
                      checked={skipConfirm}
                      onClick={() => setSkipConfirm(!skipConfirm)}
                      label={t("WITHOUT_CONFIMATION")}
                      variant="info"
                    />

                    <Checkbox
                      checked={isWarnConfirmed}
                      onClick={() => setIsWarnConfirmed(!isWarnConfirmed)}
                      label={t("PROCEDURE_WARN")}
                      variant="warn"
                    />

                    {!is2FAEnabled && (
                      <Warning
                        text={t("TWO_FA_FOR_WITHDRAW")}
                        extraNode={
                          <MainButton
                            maxWidth="14rem"
                            color="secondary"
                            onClick={() => change2Fa()}
                          >
                            <LockIcon />
                            {t("ENABLE")}
                          </MainButton>
                        }
                      />
                    )}

                    {hasError && <ModalError text={hasError} />}

                    {skipConfirm && (
                      <InputContainer $marginTop>
                        <AppText>{t("2FA Code")}</AppText>
                        <TextInput
                          placeholder={t("CODE")}
                          value={twoFACode}
                          onChange={({ target: { value } }) =>
                            setTwoFACode(value)
                          }
                          leftIcon={<LockIcon />}
                          autoComplete="off"
                          name="2fa-code"
                        />
                      </InputContainer>
                    )}

                    <ModalButton
                      disabled={
                        Number(currencyValue) <= 0 ||
                        !!hasError ||
                        !isWarnConfirmed ||
                        (currency !== "EUR" && !chosenNetwork) ||
                        !is2FAEnabled ||
                        isLoading ||
                        // (chosenNetwork !== bankWithdraw &&
                        // chosenNetwork !== transferName &&
                        // withdrawAddress.length < 4)
                        (currency !== "EUR" && withdrawAddress.length < 4) ||
                        (currency === "EUR" &&
                          // chosenNetwork === bankWithdraw &&
                          !values.beneficiaryName) ||
                        (currency === "EUR" &&
                          // chosenNetwork === bankWithdraw &&
                          Object.keys(errors).length !== 0) ||
                        // chosenNetwork !== transferName &&
                        (hasMemo && withdrawMemo.length < 4) ||
                        // (chosenNetwork === transferName && !receiverEmail) ||
                        // (chosenNetwork === transferName && !transferNote) ||
                        (skipConfirm && !twoFACode)
                      }
                      type="submit"
                    >
                      {t("WITHDRAW")}{" "}
                      {Number(currencyValue) > 0 &&
                        toLocaleStringWithCurrency(
                          Number(currencyValue),
                          currency || "USDT",
                          currencies
                        )}{" "}
                      {Number(currencyValue) > 0 && fees
                        ? `+ ${fees} ${currency || "USDT"} Fees`
                        : ""}
                    </ModalButton>
                  </SemiContainer>
                </>
              )}
            </>
          )}
        </InnerContentForm>

        <PaymentSelect
          onClose={closeSelect}
          isOpen={isSelectOpen}
          selected={paymentType}
          options={currenciesOptions}
          setValue={onPaymentChange}
        />
        <TwoFaModal
          isOpen={isTwoFaOpen}
          url={twoFaUrl}
          onClose={() => setIsTwoFaOpen(false)}
        />
      </ModalContent>
    </Fade>
  );
};

export default observer(MainWithdrawStep);
