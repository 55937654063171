import axios, { AxiosResponse } from "axios";
// import type {
//   CoinsMarketsByFiat,
//   CoinInfo,
//   CoingeckoCoinsInfo,
// } from "./types/coinInfo";
import type { GetCoinOHLCInterface } from "helpers/types";

export const getCoinOHLCByFiat = ({
  fiatId,
  coinId,
  days,
}: GetCoinOHLCInterface): Promise<
  AxiosResponse<[number, number, number, number, number][]>
> =>
  axios(
    `https://api.coingecko.com/api/v3/coins/${coinId}/ohlc?vs_currency=${fiatId}&days=${days}`
  );

// export const getAllAvailableCoingekoCoinsList = () =>
//   axios<CoingeckoCoinsInfo[]>("https://api.coingecko.com/api/v3/coins/list");

// export const getCoinsMarketsByFiat = (fiatId: string) =>
//   axios<CoinsMarketsByFiat[]>(
//     `https://api.coingecko.com/api/v3/coins/markets?vs_currency=${fiatId}&ids=${coinsMarketsIds}&order=market_cap_desc&per_page=30&page=1&sparkline=false`
//   );

// export const getCoinStats = (coinId: string) =>
//   axios<CoinInfo>(
//     `https://api.coingecko.com/api/v3/coins/${coinId}?tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false`
//   );

export const getCopyTradingStats = () =>
  fetch(
    `https://scaf-b58ff-default-rtdb.europe-west1.firebasedatabase.app/scaf/returns_13.json`
  );
