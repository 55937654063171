import { FC, ReactNode } from "react";
import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import styled from "styled-components";
import { colors, mainFont } from "helpers/consts";

import { ReactComponent as CheckIcon } from "assets/icons/checkbox-check.svg";

interface StyledCheckboxProps extends CheckboxProps {
  label: string | ReactNode;
  variant?: "warn" | "info" | "dark";
  isTransparent?: boolean;
  isAlignTop?: boolean;
  maxWidth?: string;
}

const StyledCheckbox: FC<StyledCheckboxProps> = ({
  label,
  variant,
  isTransparent,
  isAlignTop,
  style,
  maxWidth,
  ...props
}) => (
  <>
    <StyledFormControl
      control={
        <Checkbox
          sx={{
            "&:hover": { bgcolor: "transparent" },
          }}
          checkedIcon={<CheckedCheckboxIcon />}
          icon={<CheckboxIcon />}
          {...props}
        />
      }
      label={label || ""}
      $variant={variant}
      $isTransparent={isTransparent}
      $isAlignTop={isAlignTop}
      $maxWidth={maxWidth}
      style={style}
    />
  </>
);

const CheckedCheckboxIcon: FC = () => (
  <CheckedCheckbox>
    <CheckIcon />
  </CheckedCheckbox>
);

interface StyledFormControlInterface {
  $variant?: "warn" | "info" | "dark";
  $isTransparent?: boolean;
  $isAlignTop?: boolean;
  $maxWidth?: string;
}
const StyledFormControl = styled(FormControlLabel)<StyledFormControlInterface>`
  width: 100%;
  margin: 0rem;
  padding-right: 2.4rem;
  background-color: ${({ $isTransparent }) =>
    $isTransparent ? "transparent" : colors.gray_50};
  border-radius: 0.8rem;
   ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}

  ${({ $variant }) => {
    if ($variant === "warn") {
      return `background-color: ${colors.error_50};`;
    }
    if ($variant === "info") {
      return `background-color: ${colors.primary_50};`;
    }
    if ($variant === "dark") {
      return `background-color: ${colors.dark};`;
    }
  }}
}
${({ $isAlignTop }) => $isAlignTop && "align-items: flex-start;"}


  span.MuiCheckbox-root {
    padding: 1.2rem;
    background-color: transparent;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover  {
      background-color: ${({ $variant }) =>
        $variant === "dark" ? colors.gray_700 : colors.gray_200};
    },

    /* input:disabled + span {
      box-shadow: "none";
      background: rgba(57,75,89,.5);
    },

    input:hover + span  {
      background-color: #30404d;
    }, */
  }

  .MuiTypography-root {
    font-size: 1.4rem;
    font-family: ${mainFont};
    color: ${({ $variant }) =>
      $variant === "dark" ? colors.gray_400 : colors.gray_600};
    font-weight: 500;
    padding: 0.8rem 0;
  }
`;

const CheckboxIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid ${colors.gray_300};
  background-color: ${colors.white};
  
  .Mui-focusVisible & {
    outline: 2px auto rgba(19,124,189,.6);
    outline-offset: 2px;
  },
`;

const CheckedCheckbox = styled(CheckboxIcon)`
  border: 1px solid ${colors.primary_600};
  background-color: ${colors.primary_50};

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export default StyledCheckbox;
