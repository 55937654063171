export const exceedAmount = "ERROR_NO_AMOUNT";
export const lowerThanMinAmount = "ERROR_MIN_AMOUNT";
export const higherThanMaxAmount = "ERROR_MAX_AMOUNT";
export const metamaskChainError = "ERROR_METAMASK_CHAIN";
export const unavailablePair = "CURRENTLY_UNAVAILABLE_CURRENCY";

export const requiredField = "REQUIRED_FIELD";
export const invalidPhone = "INVALID_PHONE_NUMBER";

export const limitError = "LIMIT_ERROR";
export const stopBybitError = "STOP_BYBIT_ERROR";
export const limitBybitError = "LIMIT_BYBIT_ERROR";
