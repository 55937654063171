import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors, currencyIcons } from "helpers/consts";
import { ModalButton } from "components/Buttons";
import { AppText } from "components";
import OrderInfoRows from "./OrderInfoRows";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  StatusInfo,
  InfoColumn,
  StatusInfoLine,
} from "../styled";
import type { ByBitInstrument, CurrencyInterface } from "helpers/types";

import boughtImg from "assets/images/bought_success.jpg";
import sellImg from "assets/images/sell_success.jpg";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";

interface SuccessOrderStepProps {
  onClose: () => void;
  leftCurrencyValue: string;
  rightCurrencyValue: string;
  activeInstrument: ByBitInstrument | null;
  cost: number;
  tradeType: number;
  currencies: CurrencyInterface[] | null;
  operation: number;
  fees: number;
}

const SuccessOrderStep: FC<SuccessOrderStepProps> = ({
  onClose,
  leftCurrencyValue,
  rightCurrencyValue,
  activeInstrument,
  cost,
  tradeType,
  currencies,
  operation,
  fees,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <StatusContent>
        <StatusDecorative>
          <img src={tradeType === 0 ? boughtImg : sellImg} alt="decorative" />
          <StatusIconContainer>
            <CheckIcon />
          </StatusIconContainer>
        </StatusDecorative>
        <StatusInfo>
          <InfoColumn>
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_600}
              lineHeight={30}
            >
              {activeInstrument!.baseCoin}/{activeInstrument!.quoteCoin}
            </AppText>
            <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
              {t(
                operation === 0
                  ? "LIMIT_ORDER_CONFIRMED"
                  : "MARKET_ORDER_CONFIRMED"
              )}
            </AppText>
            <StatusInfoLine>
              {currencyIcons[activeInstrument!.baseCoin] ||
                currencyIcons.not_found}
              <AppText
                fontSize={20}
                fontWeight={600}
                color={colors.gray_700}
                lineHeight={30}
              >
                {tradeType === 1
                  ? normolizeDecimalsWithNoCode(
                      Number(leftCurrencyValue),
                      activeInstrument!.baseCoin,
                      currencies
                    )
                  : normolizeDecimalsWithNoCode(
                      Number(rightCurrencyValue),
                      activeInstrument!.baseCoin,
                      currencies
                    )}
              </AppText>
              <AppText
                isSpan
                fontSize={20}
                fontWeight={600}
                color={colors.gray_400}
                lineHeight={30}
              >
                {` ${activeInstrument!.baseCoin}`}
              </AppText>
            </StatusInfoLine>
          </InfoColumn>

          <OrderInfoRows
            leftCurrencyValue={leftCurrencyValue}
            rightCurrencyValue={rightCurrencyValue}
            activeInstrument={activeInstrument}
            tradeType={tradeType}
            cost={cost}
            currencies={currencies}
            fees={fees}
          />

          <ModalButton onClick={onClose}>{t("DONE")}</ModalButton>
        </StatusInfo>
      </StatusContent>
    </Fade>
  );
};

export default SuccessOrderStep;
