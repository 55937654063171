import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { CloseModalButton } from "components/Buttons";
import BasicModal from "./BasicModal";
import { ModalHeading, ModalContent, InfoRows } from "./styled";
import { ModalRow } from "components/Rows";
import type { HistoryiItemDetails } from "api/types/wallet";

const translationKeys: { [k: string]: string } = {
  toAddress: "TO_ADDRESS",
  blockchainTxId: "Hash",
  withdrawStatus: "STATUS",
  withdrawFailedReason: "FAILED_REASON",
  bet: "BET",
  win: "WIN",
  profit: "PROFIT",
};
interface TransactionDetailsModalProps {
  info: HistoryiItemDetails | null;
  isOpen: boolean;
  onClose: () => void;
  resetState: () => void;
}
const TransactionDetailsModal: FC<TransactionDetailsModalProps> = ({
  info,
  onClose,
  isOpen,
  resetState,
}) => {
  const { t } = useTranslation();
  return (
    <BasicModal isOpen={isOpen} onClose={onClose} onExited={resetState}>
      <ModalContent>
        <CloseModalButton onClose={onClose} label="close" />
        <ModalHeading $centered>Details</ModalHeading>
        <InfoRows>
          {info &&
            Object.entries(info)
              .filter((el) => el[1])
              .map((el) => (
                <ModalRow
                  keyText={`${
                    translationKeys[el[0]] ? t(translationKeys[el[0]]) : el[0]
                  }:`}
                  value={el[1] || ""}
                />
              ))}
        </InfoRows>
      </ModalContent>
    </BasicModal>
  );
};

export default observer(TransactionDetailsModal);
