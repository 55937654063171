import { FC, Dispatch, SetStateAction, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import {
  ModalButton,
  BackModalButton,
  CloseModalButton,
} from "components/Buttons";
import TradeInfoRows from "./TradeInfoRows";
import { AppText, Checkbox, PairCoins } from "components";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import {
  ModalContent,
  ModalHeading,
  InnerContentForm,
  ConfirmBlock,
} from "../styled";
import type {
  AvailableTradeOptions,
  MarketsInterface,
  CurrencyInterface,
  TradeOperationSide,
  TradeOperationType,
} from "helpers/types";

interface MainTradeStepProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setSkipConfirm: Dispatch<SetStateAction<boolean>>;
  skipConfirm: boolean;
  onBack: () => void;
  onClose: () => void;
  leftCurrencyValue: string;
  rightCurrencyValue: string;
  leftCurrency: AvailableTradeOptions;
  rightCurrency: AvailableTradeOptions;
  operationType: TradeOperationSide;
  cost: number;
  marketType: TradeOperationType;
  currentExchangeMarket: MarketsInterface | null;
  currencies: CurrencyInterface[] | null;
  isStatic?: boolean;
  isLoading: boolean;
  currentFee: number;
}

const ConfirmTradeStep: FC<MainTradeStepProps> = ({
  onSubmit,
  skipConfirm,
  setSkipConfirm,
  onBack,
  onClose,
  leftCurrencyValue,
  rightCurrencyValue,
  leftCurrency,
  rightCurrency,
  operationType,
  cost,
  currentFee,
  currentExchangeMarket,
  marketType,
  currencies,
  isStatic,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <ModalContent>
        {isStatic ? (
          <CloseModalButton onClose={onClose} />
        ) : (
          <BackModalButton onBack={onBack} />
        )}

        <ModalHeading $centered>{t("TRADE_REVIEW")}</ModalHeading>
        <InnerContentForm onSubmit={(e) => onSubmit(e)}>
          <ConfirmBlock>
            <PairCoins
              from={currentExchangeMarket!.leftCurrencyName}
              to={currentExchangeMarket!.rightCurrencyName}
              forModal
            />
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
              style={{ marginTop: "0.4rem" }}
            >
              {currentExchangeMarket!.name}
            </AppText>
            <AppText
              fontSize={16}
              style={{ textTransform: "capitalize" }}
              fontWeight={400}
              color={colors.gray_700}
            >
              {marketType} {operationType === "buy" ? t("PURCHASE") : t("SALE")}
            </AppText>
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
            >
              {operationType === "sell"
                ? normolizeDecimalsWithNoCode(
                    Number(leftCurrencyValue),
                    leftCurrency,
                    currencies
                  )
                : normolizeDecimalsWithNoCode(
                    Number(rightCurrencyValue),
                    rightCurrency,
                    currencies
                  )}
              <AppText
                isSpan
                fontSize={20}
                fontWeight={600}
                color={colors.gray_400}
                lineHeight={30}
              >
                {` ${operationType === "sell" ? leftCurrency : rightCurrency}`}
              </AppText>
            </AppText>
          </ConfirmBlock>

          <TradeInfoRows
            leftCurrencyValue={leftCurrencyValue}
            rightCurrencyValue={rightCurrencyValue}
            leftCurrency={leftCurrency}
            rightCurrency={rightCurrency}
            currentFee={currentFee}
            cost={cost}
            currentExchangeMarket={currentExchangeMarket}
            currencies={currencies}
            marketType={marketType}
          />

          <Checkbox
            checked={skipConfirm}
            onClick={() => setSkipConfirm(!skipConfirm)}
            label={t("DONT_SHOW_AGAIN")}
            variant="info"
          />

          <ModalButton type="submit" disabled={isLoading}>
            {t("CONFIRM")}
          </ModalButton>
        </InnerContentForm>
      </ModalContent>
    </Fade>
  );
};

export default ConfirmTradeStep;
