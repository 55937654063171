import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import {
  ModalButton,
  BackModalButton,
  CloseModalButton,
} from "components/Buttons";
import { AppText, PairCoins } from "components";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import {
  ModalContent,
  ModalHeading,
  InnerContent,
  ConfirmBlock,
  ConfirmInfo,
} from "../styled";
import type { ByBitInstrument } from "helpers/types";
import type { ByBitOrder } from "api/types/bybit";

interface ConfirmCancelStepProps {
  onSubmit: () => void;
  onBack: () => void;
  onClose: () => void;
  orderToCancel: ByBitOrder | null;
  usedInstrument?: ByBitInstrument;
  isLoading: boolean;
}

const ConfirmCancelStep: FC<ConfirmCancelStepProps> = ({
  onSubmit,
  onBack,
  onClose,
  orderToCancel,
  isLoading,
  usedInstrument,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <ModalContent>
        <CloseModalButton onClose={onClose} />
        <BackModalButton onBack={onBack} />

        <ModalHeading $centered>{t("CANCEL_ORDER_CONFIRMATION")}</ModalHeading>
        <InnerContent>
          <ConfirmBlock>
            {usedInstrument && (
              <PairCoins
                from={usedInstrument.baseCoin}
                to={usedInstrument.quoteCoin}
                forModal
              />
            )}
            <AppText color={colors.gray_700} style={{ marginTop: "0.6rem" }}>
              {usedInstrument
                ? `${usedInstrument?.baseCoin}/${usedInstrument?.quoteCoin}`
                : orderToCancel?.symbol || ""}
            </AppText>

            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_700}
              lineHeight={30}
            >
              {orderToCancel?.side}{" "}
              {normolizeDecimalsWithNoCode(
                Number(orderToCancel?.qty) || 0,
                usedInstrument?.baseCoin || "USDT"
              )}
              <AppText
                isSpan
                fontSize={20}
                fontWeight={600}
                color={colors.gray_400}
                lineHeight={30}
              >
                {" "}
                {usedInstrument?.baseCoin || "USDT"}
              </AppText>
            </AppText>
            <ConfirmInfo>
              <AppText fontWeight={400} color={colors.gray_700}>
                {t("CANCEL_ORDER")}?
              </AppText>
            </ConfirmInfo>
          </ConfirmBlock>

          <ModalButton onClick={onSubmit} disabled={isLoading}>
            {t("CANCEL_CONFIRM")}
          </ModalButton>
        </InnerContent>
      </ModalContent>
    </Fade>
  );
};

export default ConfirmCancelStep;
