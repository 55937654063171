import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { CloseModalButton, ModalButton } from "components/Buttons";
import BasicModal from "./BasicModal";
import { ModalHeading, ModalContent } from "./styled";
import { UserStore } from "stores";
import { AppText, Link } from "components";
import { colors, supportUrl } from "helpers/consts";

const TelegramErrorModal: FC = () => {
  const { t } = useTranslation();
  const { telegramLoginError, isTelegramLoginModalErrorIsOpen } = UserStore;

  return (
    <BasicModal
      isOpen={isTelegramLoginModalErrorIsOpen}
      onClose={() => window.Telegram!.WebApp.close()}
    >
      <ModalContent $lowMargin>
        <CloseModalButton
          onClose={() => window.Telegram!.WebApp.close()}
          label="close"
        />
        <ModalHeading $centered>{t("AUTH_ERROR")}</ModalHeading>

        <AppText
          color={colors.error_400}
          fontSize={18}
          style={{ marginBottom: "1rem" }}
          fontWeight={600}
        >
          <b style={{ color: colors.gray_900 }}>Error:</b>{" "}
          {telegramLoginError || "Unknown error"}
        </AppText>

        <AppText
          color={colors.gray_900}
          fontSize={15}
          style={{ marginBottom: "1rem" }}
          fontWeight={600}
        >
          {t("TRY_AGAIN_LATER_OR")}{" "}
          <Link isOuterLink isEmail to={supportUrl}>
            {t("CONTACT_SUPPORT")}
          </Link>
        </AppText>

        <ModalButton onClick={() => window.Telegram!.WebApp.close()}>
          Close
        </ModalButton>
      </ModalContent>
    </BasicModal>
  );
};

export default observer(TelegramErrorModal);
