import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalRow } from "components/Rows";
import { Accordion } from "components";
import { toLocaleStringWithCurrency } from "helpers/funcs";
import { InfoRows } from "../styled";
import type { CurrencyInterface, ByBitInstrument } from "helpers/types";

interface TradeInfoRowsProps {
  leftCurrencyValue: string;
  rightCurrencyValue: string;
  cost: number;
  currencies: CurrencyInterface[] | null;
  activeInstrument: ByBitInstrument | null;
  tradeType: number;
  fees: number;
}

const TradeInfoRows: FC<TradeInfoRowsProps> = ({
  leftCurrencyValue,
  rightCurrencyValue,
  cost,
  currencies,
  activeInstrument,
  tradeType,
  fees,
}) => {
  const { t } = useTranslation();

  return (
    <Accordion
      label={t("YOU_WILL_RECEIVE")}
      subLabel={toLocaleStringWithCurrency(
        Number(rightCurrencyValue) - fees,
        tradeType === 0
          ? activeInstrument!.baseCoin
          : activeInstrument!.quoteCoin,
        currencies
      )}
    >
      <InfoRows>
        <ModalRow
          keyText={t("RATE")}
          value={toLocaleStringWithCurrency(
            cost,
            activeInstrument!.quoteCoin,
            currencies
          )}
          isBold
        />
        <ModalRow
          keyText={t("YOU_SPEND")}
          value={toLocaleStringWithCurrency(
            Number(leftCurrencyValue),
            tradeType === 0
              ? activeInstrument!.quoteCoin
              : activeInstrument!.baseCoin,
            currencies
          )}
        />
        <ModalRow
          keyText={t("FEE")}
          value={toLocaleStringWithCurrency(
            Number(fees),
            tradeType === 0
              ? activeInstrument!.baseCoin
              : activeInstrument!.quoteCoin,
            currencies
          )}
        />
      </InfoRows>
    </Accordion>
  );
};

export default TradeInfoRows;
