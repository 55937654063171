import { FC } from "react";
import styled from "styled-components";
import { AppText, Chip, Tooltip, Link } from "components";
import { ChipView } from "pages/DashboardPage/components/BalanceSection/styled";
import { colors } from "helpers/consts";

import { ReactComponent as InfoIcon } from "assets/icons/info-circle.svg";

interface ModalRowProps {
  keyText: string;
  value?: string | number;
  subValue?: string | number;
  isBold?: boolean;
  isChip?: boolean;
  isInfoChip?: boolean;
  linkUrl?: string;
  noMargin?: boolean;
  noPadding?: boolean;
  tooltip?: string;
  darkTheme?: boolean;
}

const ModalRow: FC<ModalRowProps> = ({
  keyText,
  value = "-",
  subValue,
  isBold,
  isChip,
  isInfoChip,
  linkUrl,
  tooltip,
  noMargin,
  noPadding,
  darkTheme,
}) => (
  <RowContainer $isBold={isBold} $noMargin={noMargin} $noPadding={noPadding}>
    <RowInfoLine>
      <AppText color={darkTheme ? colors.gray_400 : colors.gray_600}>
        {keyText}
      </AppText>
      {tooltip && (
        <Tooltip title={tooltip}>
          <InfoIcon />
        </Tooltip>
      )}
    </RowInfoLine>

    {isChip ? (
      isInfoChip ? (
        <ChipView>{value}</ChipView>
      ) : (
        <Chip isSuccess withBorder>
          {value}
        </Chip>
      )
    ) : linkUrl ? (
      <Link isOuterLink to={linkUrl}>
        Link
      </Link>
    ) : (
      <AppText color={darkTheme ? colors.gray_400 : colors.gray_600}>
        {value}
        {subValue && (
          <AppText isSpan color={darkTheme ? colors.gray_600 : colors.gray_400}>
            {" "}
            ({subValue})
          </AppText>
        )}
      </AppText>
    )}
  </RowContainer>
);

interface RowContainerProps {
  $isBold?: boolean;
  $noMargin?: boolean;
  $noPadding?: boolean;
}
const RowContainer = styled.div<RowContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ $noPadding }) => !$noPadding && "padding: 1rem 0;"}
  ${({ $noMargin }) => !$noMargin && "margin: 0 1.2rem;"}

  & > *:not(:first-child) {
    margin-left: 1rem;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.gray_100};
  }

  & > p.MuiTypography-root {
    font-weight: ${({ $isBold }) => ($isBold ? "600" : "400")};
  }

  & > p {
    text-align: right;
  }
`;

const RowInfoLine = styled.div`
  display: flex;
  flex-wrap: nowrap;
  text-align: right;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.4rem;
  }

  > p {
    text-align: left;
  }
`;

export default ModalRow;
