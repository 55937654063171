import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import { ModalButton, CloseModalButton } from "components/Buttons";
import { AppText } from "components";
import {
  StatusIconContainer,
  ModalContent,
  ModalHeading,
  InnerContent,
  ConfirmBlock,
  SuccessContent,
} from "../styled";

import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";

interface SuccessCancelStepProps {
  onClose: () => void;
  canceledOrderId: string;
}

const SuccessCancelStep: FC<SuccessCancelStepProps> = ({
  onClose,
  canceledOrderId,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <SuccessContent>
        <ModalContent>
          <CloseModalButton onClose={onClose} />
          <ModalHeading>{t("CANCEL_ORDER")}</ModalHeading>
          <InnerContent>
            <ConfirmBlock>
              <StatusIconContainer $isStatic>
                <CheckIcon />
              </StatusIconContainer>

              <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
                ID: {canceledOrderId}
              </AppText>
              <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
                {t("ORDER_CANCELED")}
              </AppText>
            </ConfirmBlock>
            <ModalButton onClick={onClose}>{t("DONE")}</ModalButton>
          </InnerContent>
        </ModalContent>
      </SuccessContent>
    </Fade>
  );
};

export default SuccessCancelStep;
