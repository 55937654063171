import { FC, useState, useCallback, useMemo, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BasicModal from "../BasicModal";
import { Preloader } from "components";
import ConfirmCancelStep from "./ConfirmCancelStep";
import SuccessCancelStep from "./SuccessCancelStep";
import { cancelOrder } from "api/byBit";
import { GlobalStore, ByBitStore } from "stores";
import type { ModalStepsType, ByBitInstrument } from "helpers/types";
import type { ByBitOrder, ByBitCancelOrderReq } from "api/types/bybit";

interface StakeModalProps {
  isOpen: boolean;
  onClose: () => void;
  orderToCancel: ByBitOrder | null;
}
const CancelOrderModal: FC<StakeModalProps> = ({
  isOpen,
  onClose,
  orderToCancel,
}) => {
  const { t } = useTranslation();

  const { instrumentsList } = ByBitStore;

  const [step, setStep] = useState<ModalStepsType>("confirm");
  const [currentOrderToCancel, setCurrentOrderToCancel] =
    useState<ByBitOrder | null>(null);
  const [canceledOrderId, setCanceledOrderId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCancelOrderSubmit = () => {
    const unstakeData: ByBitCancelOrderReq = {
      category: "spot",
      symbol: currentOrderToCancel!.symbol,
      orderId: currentOrderToCancel!.orderId,
    };

    setIsLoading(true);

    cancelOrder(unstakeData)
      .then(({ data }) => {
        setCanceledOrderId(data.orderId);
        setStep("success");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isOpen) {
      setCurrentOrderToCancel(orderToCancel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderToCancel]);

  const usedInstrument = useMemo<ByBitInstrument | undefined>(
    () =>
      instrumentsList?.find(
        (instrument) => instrument.symbol === currentOrderToCancel?.symbol
      ),
    [currentOrderToCancel, instrumentsList]
  );

  const closeModal = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const resetState = useCallback(() => {
    setStep("confirm");
    setCanceledOrderId("");
    setCurrentOrderToCancel(null);
  }, []);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={resetState}
      onClose={closeModal}
      title={t("CANCEL_ORDER")}
    >
      {isLoading && <Preloader isStatic />}
      {step === "confirm" && (
        <ConfirmCancelStep
          onSubmit={onCancelOrderSubmit}
          orderToCancel={currentOrderToCancel}
          onBack={closeModal}
          onClose={closeModal}
          isLoading={isLoading}
          usedInstrument={usedInstrument}
        />
      )}
      {step === "success" && (
        <SuccessCancelStep
          canceledOrderId={canceledOrderId}
          onClose={closeModal}
        />
      )}
    </BasicModal>
  );
};

export default observer(CancelOrderModal);
